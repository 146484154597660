
<template>
  <section id="footer" class="h-10AAA h-10 py-2AAA sm:h-7 sm:py-2 ">
    <div class="flex items-center">
      <div class="flex "></div>
      <div class="flex-1  sm:text-xs md:text-xs text-sm ">
        <p>Omneris Ltd &copy; {{ currentYear }}. {{ msg }}</p>
      </div>
      <div class="flex ">
        <div class="flex items-end space-x-4 mr-4">
          <div class="flex-1"></div>
<!--          <div class="flex-none">
            <router-link to="/legal" class="underline text-blue-600 visited:text-purple-600">Legal</router-link>
          </div>
          <div class="flex-none">
            <router-link to="/help" class="underline text-blue-600 visited:text-purple-600">Help</router-link>
          </div>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">

import {defineComponent} from "vue";

// import {Options, Vue} from "vue-class-component";

export default defineComponent({
  name: 'AppFooter',
  props: {
    msg: String
  },
  setup() {
    return {
      currentYear: new Date().getFullYear().toString()
    }
  },
  computed: {

  }
})


</script>

<style >

</style>