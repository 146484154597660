
import {computed, defineComponent} from 'vue';
import {useAppSettings} from "@/main";
import {IBrand} from "@/types/Brand.interface";
import AppFooter from "@/components/AppFooter.vue";
// import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'Home',
  components: {
    // HelloWorld,
    // AppFooter
  },
  setup(props, ctx) {

    const {stg} = useAppSettings()
    const brands = computed(() => stg.brands)
    const page = computed(() => stg.page.home)
    const toImagePath = (path?:string):string | undefined => {
      if (!path) { return undefined }
      console.log(path);
      return require(`${path}`)
    }
    return {
      page,
      brands,
      publicPath: process.env.BASE_URL,
      toImagePath
    }
  }
});
