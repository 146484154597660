import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import {BrandPageTypes, PageTypes} from "@/types/AppTypes"
import Home from '../views/Home.vue'
import BrandsPage from "@/views/brands/BrandsPage.vue";
import BrandVelosofiPage from "@/views/brands/BrandVelosofiPage.vue";
import BrandIseatuWorkPage from "@/views/brands/BrandIseatuWorkPage.vue";
import BrandIseatuShoppingPage from "@/views/brands/BrandIseatuShoppingPage.vue";
import BrandThisElementPage from "@/views/brands/BrandThisElementPage.vue";
import {nth} from "lodash/fp";
import {useInertOthers} from "@headlessui/vue/dist/hooks/use-inert-others";
import NotFoundPage from "@/components/NotFoundPage.vue";

export interface IRouterPathInfo {
  /// the id for this
  id: string
  path: string
  name?: string | symbol
  children: Record<string, IRouterPathInfo>
}

export interface IPathLevels {
  level_1?:string
  level_2?:string
  level_3?:string
  level_4?:string
}

export class PathLevels implements IPathLevels {
  level_1?: string;
  level_2?: string;
  level_3?: string;
  level_4?: string;

  constructor(level_1?: string, level_2?: string, level_3?: string, level_4?: string) {
    this.level_1 = level_1;
    this.level_2 = level_2;
    this.level_3 = level_3;
    this.level_4 = level_4;
  }

  public static zero():IPathLevels {
    return new PathLevels(undefined, undefined, undefined, undefined)
  }

  public static makeFromPath(path?:string): IPathLevels {
    let level_1:string | undefined;
    let level_2:string | undefined;
    let level_3:string | undefined;
    let level_4:string | undefined;
    if (!path || !path.startsWith('/')) {
      return new PathLevels(level_1, level_2, level_3, level_4);
    }
    const parts = path.split('/').filter((s) => !!s)
    const n_parts = parts.length;
    if (n_parts === 4) {
      level_4 = nth(3, parts);
      level_3 = nth(2, parts);
      level_2 = nth(1, parts);
      level_1 = nth(0, parts);
    }else if (n_parts === 3) {
      level_3 = nth(2, parts);
      level_2 = nth(1, parts);
      level_1 = nth(0, parts);
    }else if (n_parts === 2) {
      level_2 = nth(1, parts);
      level_1 = nth(0, parts);
    }else if (n_parts === 1) {
      level_1 = nth(0, parts);
    }// else implied
    return new PathLevels(level_1, level_2, level_3, level_4);
  }

  public static pathIsLevel1(path_level?:string): string | undefined {
    if (path_level === undefined) { return undefined }
    const has_level = Object.values(PageTypes).map((v) => v.toString()).includes(path_level.toLowerCase())
    return has_level ? path_level : undefined
  }


  /** whether
   * @param {string?} path_level the path */
  public static pathIsBrandPageLevel1(path_level?:string): string | undefined {
    if (path_level === undefined) { return undefined }
    const has_level = Object.values(BrandPageTypes).map((v) => v.toString()).includes(path_level.toLowerCase())
    return has_level ? path_level : undefined;
  }

}

export class RouterPathInfo implements IRouterPathInfo {

  id: string;
  name?: string | symbol;
  path: string;
  children: Record<string, IRouterPathInfo>;

  constructor(id: string, path: string, children: Record<string, IRouterPathInfo>, name?: string | symbol) {
    ///
    this.id = id;
    this.name = name;
    this.path = path;
    this.children = children;
    ///
  }

  /** convert path into levels [ e.g /brands/velosofi/, into 4 levels {level_1: brands, level_2: velosofi} ].
   * @param {String?} path the URI path
   * @return {String | undefined} the first part of a URI path if present or undefined
   * */
  public static getLevelPaths(path?:string): IPathLevels {
    return PathLevels.makeFromPath(path);
  }

  /** from a route path (/brands/velosofi/) take the first part (brands), or nothing.
   * @param {String?} path the URI path
   * @return {String | undefined} the first part of a URI path if present or undefined
   * */
  public static getFirstPartFromPath(path?:string): string | undefined {
    if (!path || !path.startsWith('/')) {
      return undefined;
    }
    const parts = path.split('/').filter((s) => !!s)
    const n_parts = parts.length;
    if (n_parts !== 1) {
      return undefined;
    }
    const first = nth(0, parts);
    if (!first) {
      return undefined;
    }
    return first;
  }

  public static makeFromRouteRecord(raw:RouteRecordRaw):IRouterPathInfo | undefined {
    //
    const path = raw.path;
    const id = RouterPathInfo.getFirstPartFromPath(path);
    if (!id) {
      return undefined;
    }
    const name = raw.name;
    const children:Record<string, IRouterPathInfo> = {};
    //
    for (const entry of raw.children || []) {
      const c = RouterPathInfo.makeFromRouteRecord(entry);
      if (c) {
        children[c.id] = c
      }
    }
    //
    const out:IRouterPathInfo = new RouterPathInfo(id, path, children, name)
    return out;
  }

  public static getPathsFromRoutes(routes:IRouterPathInfo[]): string[] {
    const out:string[] = []
    for (const entry of routes) {
      out.push(entry.id);
      if (!Object.keys(entry.children).length) {
        continue
      }
      const paths = RouterPathInfo.getPathsFromRoutes(Object.values(entry.children));
      if (!paths.length) {
        continue
      }
      out.concat(paths)
    }
    return out;
  }
}

export interface IRoutes {
  paths: string[]
  routes: Record<string, IRouterPathInfo>
}

export class Routes implements IRoutes {
  paths: string[];
  routes: Record<string, IRouterPathInfo>;

  constructor(paths: string[], routes: Record<string, IRouterPathInfo>) {
    this.paths = paths;
    this.routes = routes;
  }

  public static makeFromRouteRecords(records:RouteRecordRaw[]):IRoutes {
    const paths:string[] = [];
    const router_paths: Record<string, IRouterPathInfo> = {};
    for (const entry of records) {
      const c = RouterPathInfo.makeFromRouteRecord(entry);
      if (!c) {
        continue
      }
      router_paths[c.id] = c;
      const _paths = RouterPathInfo.getPathsFromRoutes([c]);
      paths.concat(_paths);
    }
    const out = new Routes(paths, router_paths);
    return out;
  }

}

export interface IRoutePaths {
  paths: string[]
  router_paths: IRouterPathInfo
}

export class RoutePaths implements IRoutePaths {

  paths: string[];
  router_paths: IRouterPathInfo;

  constructor(paths: string[], router_paths: IRouterPathInfo) {
    this.paths = paths;
    this.router_paths = router_paths;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/brands',
    name: 'Brands',
    component: BrandsPage,
    children: [
      {
        path: 'velosofi',
        component: BrandVelosofiPage,
      },
      {
        path: 'iseatu-work',
        component: BrandIseatuWorkPage,
      },
      {
        path: 'iseatu-shopping',
        component: BrandIseatuShoppingPage,
      },
      {
        path: 'this-element',
        component: BrandThisElementPage,
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/:catchAll(.*)",
    name: 'NotFoundPage',
    component: NotFoundPage
  },
]

export const ALL_ROUTES = Routes.makeFromRouteRecords(routes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory(),
  routes
})

export default router
