

import {AppProfileTypes} from "@/types/AppTypes";

import {get, isPlainObject, nth, isString, isDate, isNumber} from "lodash/fp";

export function hasOwn(obj:any, prop:string): boolean {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function getRandomInt(max:number): number {
    return Math.floor(Math.random() * max);
}

export const vueAppDomElementName = '__vue_app__';
export const vueAppDomElementID = 'app';

export function nameOfClass(o: any): string {
    // https://stackoverflow.com/questions/332422/get-the-name-of-an-objects-type
    return Object.prototype.toString.call(o)
}

export const invalidDate = ():Date => {
    return new Date(NaN)
}

// export const APP_PROFILE:string = AppProfileTypes.test_public_sandbox;
export const APP_PROFILE:string = AppProfileTypes.dev;