<template>
  <div class="brand-page">

    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl space-y-3 py-3">
      <span class="block">{{brand.name}}</span>
      <span class="block text-indigo-600">{{brand.sub_header?.text}}</span>
    </h2>


    <div class="" v-for="section in brand.sections" :key="section.id">
      <div class="text-center pt-5">
        <h2 class="text-base text-black-600 text-lg font-semibold tracking-wide uppercase">{{section.name}}</h2>
      </div>

      <div class="">
        <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto" v-for="(item, index) in section.body.items" :key="index">
          {{item.text}}
          <span class="flex items-center justify-center">
            <img :src="require(`../../${item.img}`)" alt="" v-if="item.img">
          </span>
        </p>
      </div>

    </div>


<!--    <div class="text-center pt-5">
      <h2 class="text-base text-black-600 text-lg font-semibold tracking-wide uppercase">why?</h2>
    </div>

    <div class="">
      <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
        To help consumers find their ideal product, without knowing much about the market or products themselves.
      </p>
    </div>


    <div class="text-center pt-5">
      <h2 class="text-base text-black-600 text-lg font-semibold tracking-wide uppercase">HOW?</h2>
    </div>

    <div class="">
      <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
        We custom-build an AI ratings engine that is capable of studying & understanding complex, well-structured products (Insurance, Cars, Education etc).
        <br> <br>
        The customer searches for products by tweaking and setting priorities (about Market, product, themselves). <br>
        Our engine uses this data as feedback when working out how each product best suits the particular customer.
        <br> <br>
        Products are ranked, scored and color-graded for easy of use.
      </p>
    </div>

    <div class="text-center pt-5">
      <h2 class="text-base text-black-600 text-lg font-semibold tracking-wide uppercase">USP?</h2>
    </div>

    <div class="">
      <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
        Not Biased towards seller. Priority-based search.
      </p>
    </div>

    -->

<!--    <div class="text-center pt-5">
      <h2 class="text-base text-black-600 text-lg font-semibold tracking-wide uppercase">The Product</h2>
    </div>

    <div class="">
        <div class="">
          <img :src="require(`../../${brand.header}`)" alt="">
        </div>
    </div>-->

  </div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import {useAppSettings} from "@/main";

export default defineComponent({
  name: "BrandVelosofiPage",
  components: {

  },
  setup(props, ctx) {

    const {stg} = useAppSettings();
    const brand = stg.brands['velosofi']
    return {
      brand
    }
  }
})
</script>

<style>

</style>