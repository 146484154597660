


import {defineComponent, onMounted, onUnmounted, watch} from "vue";
// import {Options, Vue} from "vue-class-component";

import AppFooter from "@/components/AppFooter.vue";
import AppNavBar from "@/components/AppNavBar.vue";
import {useLocalStorage, usePreferredDark} from "@vueuse/core";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {WatchStopHandle} from "@vue/runtime-core";
import {nth} from "lodash/fp";
import {useAppSettings} from "@/main";
import {PathLevels, RouterPathInfo} from "@/router";
// import {ISearchResultItem, SearchResultItem} from "@/types/AppTypes";

// import {AppUtils} from "@/utils";
// import CoreService from "@/services/CoreService";
// import {updateAxiosBaseUrlFromProfile} from "@/main";
// import WebSocketService from "@/services/WebSocketService";
// import AccountService from "@/services/account-service";
// import {interval} from "rxjs"

/*@Options({
  components: {AppFooter}
})
export default class App extends Vue {
}
*/

export default defineComponent({
  name: 'App',
  components: {
    AppFooter,
    AppNavBar
  },
  setup() {

    const route = useRoute();
    let stopWatchingRoute:WatchStopHandle;
    const {stg} = useAppSettings();

    onMounted( () => {

      //console.log('App.onMounted - init');
      try {
        // updateAxiosBaseUrlFromProfile();
        // CoreService.updateAppProfile();
      } catch (e) {
        console.error('App.onMounted - ', e)
      }

      stopWatchingRoute = watch(route, (value, oldValue) => {
        processPath(value.path)
      });

      /*// RxJS setup:  https://www.learnrxjs.io/learn-rxjs/operators/creation/interval
      AccountService.syncUserAccount()
      //emit value in sequence every 1 second
      const source = interval(1000 * 10);

      //output: 0,1,2,3,4,5....
      const subscribe = source.subscribe(val => {
        //console.log('App.Scheduler.Interval: ', val);
        const wsStatus = WebSocketService.status();
        if (!wsStatus.open) {
          WebSocketService.restart()
              .then(function (res:any) {
                console.debug('WebSocketService.restart(): ' + res);
              })
              .catch(function (e_obj:Error) {
                console.error('WebSocketService.restart(): ' + JSON.stringify(e_obj));
              });
        }
      });
      */

      processPath(route.path);
    })

    onUnmounted(() => {
      stopWatchingRoute();
      return;
    })


    const processPathParts = (path:string) => {
      const path_levels = PathLevels.makeFromPath(path);
      if (!path_levels.level_1) {
        return
      }
      stg.nav.selected_id = path_levels.level_1
      stg.nav.path_levels = path_levels
    }

    const processPath = (path:string)=> {
      console.log('processPath: ', path);
      ensureNavSelectedIsBrand(path)
    }

    const ensureNavSelectedIsBrand = (path_part:string) => {
      processPathParts(path_part)
    }

    // is user prefers dark theme
    const isDark = usePreferredDark()

    // persist state in localStorage
    const store_local = useLocalStorage(
        'my-storage',
        {
          name: 'Apple',
          color: 'red',
        },
    )

    return {
      isDark,
      store_local
    }
  },
})

