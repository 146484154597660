

import {defineComponent} from "vue";

// import {Options, Vue} from "vue-class-component";

export default defineComponent({
  name: 'AppFooter',
  props: {
    msg: String
  },
  setup() {
    return {
      currentYear: new Date().getFullYear().toString()
    }
  },
  computed: {

  }
})


