

import {defineComponent} from "vue";
import {useAppSettings} from "@/main";

export default defineComponent({
  name: "BrandThisElementPage",
  components: {

  },
  setup(props, ctx) {

    const {stg} = useAppSettings();
    const brand = stg.brands['this-element']

    return {
      brand
    }
  }
})
