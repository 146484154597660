

import _ from "lodash";
import {Vue, VueConstructor} from "vue-class-component";
import {App} from "vue";
import {AppContext} from "@vue/runtime-core";
import {ALL_ROUTES, IPathLevels, IRoutes, PathLevels} from "@/router";
import {IBrand} from "@/types/Brand.interface";

export type TimeoutType = number;
export type FunctionVoid = (() => void);
export type FunctionArgsVoid = (...args: any[]) => void;
export type FunctionArgsAny = (...args: any[]) => any;

export type AnyString = Record<string, unknown>;
export type AnyObject = Record<string, unknown>;
export type SomeObject = Record<string, AnyObject>;


export enum AppProfileTypes {
    prod = 'prod',
    test = 'test',
    test_public = 'test_public',
    test_public_sandbox = 'test_public_sandbox',
    dev= 'dev'
}

export interface IObjectMixin {
    toObject(): AnyObject
}

export function getRandomInt(max:number): number {
    return Math.floor(Math.random() * max);
}

export function someSafeDate(from: Date | string | number | null): Date {
    return safeDate(from) || new Date();
}

export function safeDate(from: Date | string | number | null): Date | null {
    if (_.isDate(from)) {
        return from;
    }
    if (!_.isNumber(from) || !_.isString(from)) {
        return null
    }
    const out = new Date(from);
    if (!_.isDate(out)) {
        return null;
    }
    return out
}

export function objectFromJSON(text: string | undefined | null): any | null {
    const payload = typeof text === 'object' ? text : safeJSON(text) || {};
    if (!payload || payload === undefined || payload === null) {
        return null
    }
    return payload
}

export function safeJSON(from_text:string | null | undefined): any {
    if (typeof from_text !== 'string') {
        return null;
    }
    try {
        const obj = JSON.parse(from_text);
        return obj
    } catch (e) {
        return null
    }
}

export function whenArrayOfObjects(arr:any[], only_some = false): any {
    const out: any[] = [];
    if (!_.isArrayLikeObject(arr)) {
        return null
    }
    for (const el of arr) {
        if (_.isPlainObject(el)) {
            out.push(el);
        }else{
            if (!only_some) {
                return null;
            }
        }
    }
    return out;
}

export function safeJSONArray(text: string | null | undefined, object_items= false): any {
    //
    if (_.isArrayLikeObject(text)) {
        return text;
    }
    let payload: any = null;
    //
    if (typeof text === 'string') {
        payload = safeJSON(text);
    }else{
        return null;
    }
    const out = whenArrayOfObjects(payload, object_items)
    return out
}

export const emptyArrayJSON = '[]'
export const emptyObjectJSON = '{}'
export const jsonTrue = 'true';
export const jsonFalse = 'false';
export const jsonNumberZero = '0';
export const emptyText = '';
export const emptyWord = 'EMPTY';


export function safeBooleanFromJSON(o:string | undefined): boolean {
    const out = safeJSON(o);
    return out ? true : false;
}

export function safeBooleanToJSON(b:boolean | undefined | null): string {
    return !b ? jsonTrue : jsonFalse;
}

export function safeNumberFromJSON(text:string | number | undefined | null): number {
    if (typeof text === 'number') { return text }
    const out = safeJSON(text);
    if (typeof out === 'number') {
        return out;
    }
    return 0
}

export function safeNumberToJSON(b:number | undefined | null): string {
    return safeToJSON(b) || jsonNumberZero;
}

export function someSafeToJSON(o:any): string {
    return safeToJSON(o) || emptyObjectJSON;
}

export function someSafeToJSONArray(o:any): string {
    if (!_.isArrayLikeObject(o)) {
        return emptyArrayJSON
    }
    return safeToJSON(o) || emptyArrayJSON;
}

/**
 * @param {string} key
 * @param {any} value
 */
function jsonStringifyToObjectReplacer(key: any,value: any){
    if(value && value.toObject){
        return value.toObject();
    }
    return value;
}

export function stringifyObject(something: any): string | undefined {
    return JSON.stringify(something, jsonStringifyToObjectReplacer);
}


export function safeToJSON(o:any): string | null {
    if (typeof o !== 'object') {
        return null;
    }
    try {
        const obj = JSON.stringify(o);
        // const obj = stringifyObject(o)
        return obj || null
    } catch (e) {
        return null
    }
}


export function safeStringify(o:any): string | null {
    if (typeof o !== 'object') {
        return null;
    }
    try {
        // const obj = JSON.stringify(o);
        const obj = stringifyObject(o)
        return obj || null
    } catch (e) {
        return null
    }
}

export enum PageTypes {
    home ='home',
    brands = 'brands',
    about = 'about'
}

export enum BrandPageTypes {
    velosofi = 'velosofi',
    iseatu_work = 'iseatu-work',
    iseatu_shopping = 'iseatu-shopping',
    this_element = 'this-element'
}

// export type PageType = 'home' | 'brands' | 'about';

// export type IPageSettings = Record<PageType, IPageSettingInfo>
export type IPageSettings = Record<PageTypes, IPageSettingInfo>

export interface INamedURL {
    id:string
    url: string
    img?: string
    name: string
}
export interface IPageSectionItem {
    id:string
    name: string
    header: string
    body: string[]
    footer?: string
    urls: INamedURL[]
    img?: string
    item_type?: string
    items: IPageSectionItem[]
}


export interface IPageSettingInfo {
    name: string
    header?: string
    body?: string
    sections: Record<string, IPageSectionItem>
}

export interface INavSettings {
    selected_id: string
    path_levels: IPathLevels
}

export interface IMainSettings {
    brands: Record<string, IBrand>,
    routes: IRoutes,
    nav: INavSettings,
    page: IPageSettings
}

declare global {
    //
    interface Window {
        __VUE__: boolean | null
        // ekko: AnyObject | undefined | null
        omneris: {
            app: App | undefined | null,
            context?: AppContext,
            // store: Store,
            stg: IMainSettings
        }
    }
    //
    interface HTMLElement {
        __vue_app__: App | undefined | null
    }
    //
}
