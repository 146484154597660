

export interface IBrandSectionItem {
    text: string | undefined
    html: string | undefined
    img: string | undefined
    img_html: string | undefined
}

export class BrandSectionItem implements IBrandSectionItem {
    text: string | undefined;
    html: string | undefined;
    img: string | undefined;
    img_html: string | undefined;


    constructor(text: string | undefined, html: string | undefined = undefined, img: string | undefined = undefined, img_html: string | undefined = undefined) {
        this.text = text;
        this.html = html;
        this.img = img;
        this.img_html = img_html;
    }

    public static makeFromText(text: string, img?:string): IBrandSectionItem  {
        return new BrandSectionItem(text, undefined, img)
    }

    public static makeFromHtml(html: string): IBrandSectionItem  {
        return new BrandSectionItem(undefined, html)
    }
}

export interface IBrandSectionBody {
    id: string
    name: string
    header: IBrandSectionItem
    sub_header: IBrandSectionItem | undefined
    items: IBrandSectionItem[]
}

export class BrandSectionBody implements IBrandSectionBody {
    id: string;
    name: string;
    header: IBrandSectionItem;
    sub_header: IBrandSectionItem | undefined;
    items: IBrandSectionItem[];


    constructor(id: string, name: string, header: IBrandSectionItem, sub_header: IBrandSectionItem | undefined, items: IBrandSectionItem[]) {
        this.id = id;
        this.name = name;
        this.header = header;
        this.sub_header = sub_header;
        this.items = items;
    }

    public static makeFromText(id:string, name:string, header_text: string, sub_header_text: string | undefined, items:IBrandSectionItem[]): IBrandSectionBody {
        const header = BrandSectionItem.makeFromText(header_text)
        const sub_header = sub_header_text ? BrandSectionItem.makeFromText(sub_header_text) : undefined;
        return new BrandSectionBody(id, name, header, sub_header, items)
    }

    public static makeFromHtml(id:string, name:string, header_html: string, sub_header_html: string | undefined, items:IBrandSectionItem[]): IBrandSectionBody {
        const header = BrandSectionItem.makeFromHtml(header_html)
        const sub_header = sub_header_html ? BrandSectionItem.makeFromHtml(sub_header_html) : undefined;
        return new BrandSectionBody(id, name, header, sub_header, items)
    }
}

export interface BrandSectionBodyItem {
    details: string
}

export interface IBrandSection {
    id: string
    name: string
    header: IBrandSectionItem
    body: IBrandSectionBody
    footer: IBrandSectionItem | undefined
}

export class BrandSection implements IBrandSection {
    id: string;
    name: string;
    header: IBrandSectionItem;
    body: IBrandSectionBody;
    footer: IBrandSectionItem | undefined;


    constructor(id: string, name: string, header: IBrandSectionItem, body: IBrandSectionBody, footer: IBrandSectionItem | undefined) {
        this.id = id;
        this.name = name;
        this.header = header;
        this.body = body;
        this.footer = footer;
    }


}

export interface IBrandStatus {
    trading_since: Date | undefined
    active: boolean
    users: boolean
}

export class BrandStatus implements IBrandStatus {
    active: boolean;
    users: boolean;
    trading_since: Date | undefined;

    constructor(active: boolean, users: boolean, trading_since: Date | undefined) {
        this.active = active;
        this.users = users;
        this.trading_since = trading_since;
    }
}

export interface IBrandAppStore {
    id: string
    name: string
    platform: "Google" | "Apple"
    store_icon_url: string
    app_url: URL
}

const APP_STORE_ICON = ''
const GOOGLE_PLAY_STORE_ICON = ''

export class BrandAppStore implements IBrandAppStore {

    id: string;
    name: string;
    platform: "Google" | "Apple";
    store_icon_url: string;
    app_url: URL;

    constructor(id: string, name: string, platform: "Google" | "Apple", store_icon_url: string, app_url: URL) {
        this.id = id;
        this.name = name;
        this.platform = platform;
        this.store_icon_url = store_icon_url;
        this.app_url = app_url;
    }

    public static makeFromGoogleUrl(app_name: string, url:string):IBrandAppStore {
        return new BrandAppStore("google", app_name, "Google", GOOGLE_PLAY_STORE_ICON, new URL(url))
    }

    public static makeFromAppleUrl(app_name: string, url:string):IBrandAppStore {
        return new BrandAppStore("apple", app_name, "Apple", APP_STORE_ICON, new URL(url))
    }
}

export interface IBrandMeta {
    selected: boolean
    active: boolean
    checked: boolean
}

export class BrandMeta implements IBrandMeta {
    active: boolean;
    checked: boolean;
    selected: boolean;

    constructor(active = false, checked = false, selected = false) {
        this.active = active;
        this.checked = checked;
        this.selected = selected;
    }
    public static zero():IBrandMeta { return new BrandMeta() }
}

export interface IBrand {
    id:string
    name: string
    from: string
    to: string
    url: URL | undefined
    router_path: string
    app_store_urls: IBrandAppStore[]
    status: IBrandStatus
    header: IBrandSectionItem
    sub_header: IBrandSectionItem | undefined
    sections: IBrandSection[]
    meta: IBrandMeta | undefined
}

export class Brand implements IBrand {
    id: string;
    name: string;
    from: string;
    to: string;
    header: IBrandSectionItem;
    sub_header: IBrandSectionItem | undefined;
    sections: IBrandSection[];
    status: IBrandStatus;
    app_store_urls: IBrandAppStore[];
    url: URL | undefined;
    router_path: string;
    meta: IBrandMeta | undefined;


    constructor(id: string, name: string, from: string, to: string, header: IBrandSectionItem, sub_header: IBrandSectionItem | undefined, sections: IBrandSection[], status: IBrandStatus, app_store_urls: IBrandAppStore[], url: URL | undefined, router_path: string, meta: IBrandMeta | undefined) {
        this.id = id;
        this.name = name;
        this.from = from;
        this.to = to;
        this.header = header;
        this.sub_header = sub_header;
        this.sections = sections;
        this.status = status;
        this.app_store_urls = app_store_urls;
        this.url = url;
        this.router_path = router_path;
        this.meta = meta;
    }

    public static makeVeloSofi():IBrand {
        const name = "VeloSofi";
        const icon_url = 'assets/img/brands/velosofi/velosofi-icon.png'
        // const icon_url = 'assets/logo.png'
        const header:IBrandSectionItem = BrandSectionItem.makeFromText(name, icon_url);
        // const _subheader_text = "an AI ratings engine that understands complex products ..."
        const _subheader_text = "an unbiased AI adviser for consumers looking to buy advanced products ..."
        const sub_header = BrandSectionItem.makeFromText(_subheader_text)
        const status = new BrandStatus(false, false, undefined)
        const from = "2010";
        const to = "2015";

        const app_store_urls:IBrandAppStore[] = [];
        const url = new URL("https://www.VeloSofi.com");

        const _section_product_header = BrandSectionItem.makeFromText('The Product')
        const _section_product_item_1 = new BrandSectionItem('Prioritise', undefined, 'assets/img/brands/velosofi/ui-credit-card-priorities.png', undefined);
        const _section_product_item_2 = new BrandSectionItem('Search', undefined, 'assets/img/brands/velosofi/velosofi-logo-dark-gray.png', undefined);
        const _section_product_item_3 = new BrandSectionItem('Results', undefined, 'assets/img/brands/velosofi/ui-credit-card-results.png', undefined);

        const _section_product_items: IBrandSectionItem[] = [
            _section_product_item_1,
            _section_product_item_2,
            _section_product_item_3,
        ];
        const _section_product_body = new BrandSectionBody('product', 'Screenshots', BrandSectionItem.makeFromText('Screenshots about the Product'), undefined, _section_product_items)
        const _section_product_footer = undefined;

        const _section_product = new BrandSection('product', 'The Product', _section_product_header, _section_product_body, _section_product_footer)

        const _section_why_header = BrandSectionItem.makeFromText('Why')
        const _section_why_body_item_1 = BrandSectionItem.makeFromText('To help consumers find their ideal product, without knowing much about the market or products themselves.')
        const _section_why_body_items: IBrandSectionItem[] = [
            _section_why_body_item_1
        ];
        const _section_why_body = new BrandSectionBody('why', 'Why', _section_why_body_item_1, undefined, _section_why_body_items)
        const _section_why = new BrandSection('why', 'Why?', _section_why_header, _section_why_body, undefined)

        const _section_ups_header = BrandSectionItem.makeFromText('UPS?')
        const _section_ups_body_item_1 = BrandSectionItem.makeFromText('Not Biased towards seller. Priority-based search.')
        const _section_ups_body_items: IBrandSectionItem[] = [
            _section_ups_body_item_1
        ];
        const _section_ups_body = new BrandSectionBody('ups', 'ups?', _section_ups_body_item_1, undefined, _section_ups_body_items)
        const _section_ups = new BrandSection('ups', 'UPS?', _section_ups_header, _section_ups_body, undefined)


        const _section_who_header = BrandSectionItem.makeFromText('How?')
        const _section_how_body_item_1 = BrandSectionItem.makeFromText('We custom-build an AI ratings engine that is capable of studying & understanding complex, well-structured products (Insurance, Cars, Education etc).');
        const _section_how_body_item_2 = BrandSectionItem.makeFromText('The customer searches for products by tweaking and setting priorities (about Market, product, themselves). Our engine uses this data as feedback when working out how each product best suits the particular customer.');
        const _section_how_body_item_3 = BrandSectionItem.makeFromText('Products are ranked, scored and color-graded for easy of use.');

        const _section_how_body_items: IBrandSectionItem[] = [
            _section_how_body_item_1,
            _section_how_body_item_2,
            _section_how_body_item_3
        ];

        const _section_how_body = new BrandSectionBody('how', 'How?', _section_who_header, undefined, _section_how_body_items)
        const _section_how = new BrandSection('How', 'How?', _section_why_header, _section_how_body, undefined)

        const sections:IBrandSection[] = [
            _section_why,
            _section_how,
            _section_ups,
            _section_product,
        ]

        const meta = BrandMeta.zero();

        const router_path = '/brands/velosofi';

        const out = new Brand(name.toLowerCase(), name, from, to, header, sub_header, sections, status, app_store_urls, url, router_path, meta)

        return out;
    }

    public static makeIseatuMarketplace():IBrand {
        const name = "iSeatU Work";
        const icon_url = 'assets/img/brands/iseatu-work/iseatu-1024.png';
        // const icon_url = 'assets/logo.png'
        const header:IBrandSectionItem = BrandSectionItem.makeFromText(name, icon_url);
        // const _subheader_text = "a marketplace for hot-desking in cafes, pubs ..."
        const _subheader_text = "1-click hot-desking in cafes, pubs ...";
        const sub_header = BrandSectionItem.makeFromText(_subheader_text);
        const status = new BrandStatus(false, false, undefined);
        const from = "2016";
        const to = "2018";

        const app_store_urls:IBrandAppStore[] = [];
        const url = new URL("https://www.iSeatU.com");

        const _section_product_header = BrandSectionItem.makeFromText('The Product')
        const _section_product_item_1 = new BrandSectionItem('Search', undefined, 'assets/img/brands/iseatu-work/1-search.png', undefined);
        const _section_product_item_2 = new BrandSectionItem('Choose', undefined, 'assets/img/brands/iseatu-work/2-choose.png', undefined);
        const _section_product_item_3 = new BrandSectionItem('Pay', undefined, 'assets/img/brands/iseatu-work/3-book-pay.png', undefined);

        const _section_product_items: IBrandSectionItem[] = [
            _section_product_item_1,
            _section_product_item_2,
            _section_product_item_3,
        ];
        const _section_product_body = new BrandSectionBody('product', 'Screenshots', BrandSectionItem.makeFromText('Screenshots about the Product'), undefined, _section_product_items)
        const _section_product_footer = undefined;

        const _section_product = new BrandSection('product', 'The Product', _section_product_header, _section_product_body, _section_product_footer)

        const _section_why_header = BrandSectionItem.makeFromText('Why')
        const _why_text = 'To make it easy for professionals to work remotely ad-hoc in social settings';
        const _section_why_body_item_1 = BrandSectionItem.makeFromText(_why_text)
        const _section_why_body_items: IBrandSectionItem[] = [
            _section_why_body_item_1
        ];
        const _section_why_body = new BrandSectionBody('why', 'Why', _section_why_body_item_1, undefined, _section_why_body_items)
        const _section_why = new BrandSection('why', 'Why?', _section_why_header, _section_why_body, undefined)

        const _section_ups_header = BrandSectionItem.makeFromText('UPS?')
        const _section_ups_body_item_1 = BrandSectionItem.makeFromText('Work from any hospitality venues. No contract or membership fees.')
        const _section_ups_body_items: IBrandSectionItem[] = [
            _section_ups_body_item_1
        ];
        const _section_ups_body = new BrandSectionBody('ups', 'ups?', _section_ups_body_item_1, undefined, _section_ups_body_items)
        const _section_ups = new BrandSection('ups', 'UPS?', _section_ups_header, _section_ups_body, undefined)


        const _section_who_header = BrandSectionItem.makeFromText('How?')
        const _section_how_body_item_1 = BrandSectionItem.makeFromText('iSeatU is a two-sided market consisting of: Customers requesting seats at a Venue, and Helpers that earn a fee for arranging those seats.');
        const _section_how_body_item_2 = BrandSectionItem.makeFromText('The app facilitates both Requesting seating and fulfilling the service - and supports live-chat between Customer and Helper.');
        const _section_how_body_item_3 = BrandSectionItem.makeFromText("Any member can easily become a Helper, and there are no contracts with venues");

        const _section_how_body_items: IBrandSectionItem[] = [
            _section_how_body_item_1,
            _section_how_body_item_2,
            _section_how_body_item_3
        ];

        const _section_how_body = new BrandSectionBody('how', 'How?', _section_who_header, undefined, _section_how_body_items)
        const _section_how = new BrandSection('How', 'How?', _section_why_header, _section_how_body, undefined)

        const sections:IBrandSection[] = [
            _section_why,
            _section_how,
            _section_ups,
            _section_product,
        ]

        const meta = BrandMeta.zero();

        const router_path = '/brands/iseatu-work';

        const id = 'iseatu-work'
        const out = new Brand(id, name, from, to, header, sub_header, sections, status, app_store_urls, url, router_path, meta)

        return out;
    }

    public static makeIseatuPersonalShopping():IBrand {
        const name = "iSeatU Shopping";
        const icon_url = 'assets/img/brands/iseatu-shopping/iseatu-1024.png'
        // const icon_url = 'assets/logo.png'
        const header:IBrandSectionItem = BrandSectionItem.makeFromText(name, icon_url);
        // const _subheader_text = "a personal shopping service to buy from any venue ..."
        const _subheader_text = "get a personal shopper to buy & deliver the products you need from any venue ..."
        const sub_header = BrandSectionItem.makeFromText(_subheader_text)
        const status = new BrandStatus(false, false, undefined)
        const from = "2020";
        const to = "2020";

        const app_store_urls:IBrandAppStore[] = [];
        const url = new URL("https://www.iSeatU.com");

        const _section_product_header = BrandSectionItem.makeFromText('The Product')
        const _section_product_item_1 = new BrandSectionItem('Search', undefined, 'assets/img/brands/iseatu-shopping/1-search.png', undefined);
        const _section_product_item_2 = new BrandSectionItem('Tailor each product', undefined, 'assets/img/brands/iseatu-shopping/2-customise.png', undefined);
        const _section_product_item_3 = new BrandSectionItem('Assign a budget', undefined, 'assets/img/brands/iseatu-shopping/3-checkout.png', undefined);
        const _section_product_item_4 = new BrandSectionItem('Tracking & Chat', undefined, 'assets/img/brands/iseatu-shopping/4-chat.png', undefined);

        const _section_product_items: IBrandSectionItem[] = [
            _section_product_item_1,
            _section_product_item_2,
            _section_product_item_3,
            _section_product_item_4,
        ];
        const _section_product_body = new BrandSectionBody('product', 'Screenshots', BrandSectionItem.makeFromText('Screenshots about the Product'), undefined, _section_product_items)
        const _section_product_footer = undefined;

        const _section_product = new BrandSection('product', 'The Product', _section_product_header, _section_product_body, _section_product_footer)

        const _section_why_header = BrandSectionItem.makeFromText('Why')
        const _why_text = 'To give consumers a tailored high-street shopping experience without them going shopping. Ideal for busy professionals and those seeking variety and quality.';
        const _section_why_body_item_1 = BrandSectionItem.makeFromText(_why_text)
        const _section_why_body_items: IBrandSectionItem[] = [
            _section_why_body_item_1
        ];
        const _section_why_body = new BrandSectionBody('why', 'Why', _section_why_body_item_1, undefined, _section_why_body_items)
        const _section_why = new BrandSection('why', 'Why?', _section_why_header, _section_why_body, undefined)

        const _section_ups_header = BrandSectionItem.makeFromText('UPS?')
        const _section_ups_body_item_1 = BrandSectionItem.makeFromText('Unlimited product selection. Fully-tailored shopping experience.')
        const _section_ups_body_items: IBrandSectionItem[] = [
            _section_ups_body_item_1
        ];
        const _section_ups_body = new BrandSectionBody('ups', 'ups?', _section_ups_body_item_1, undefined, _section_ups_body_items)
        const _section_ups = new BrandSection('ups', 'UPS?', _section_ups_header, _section_ups_body, undefined)


        const _section_who_header = BrandSectionItem.makeFromText('How?')
        const _section_how_body_item_1 = BrandSectionItem.makeFromText('iSeatU is a two-sided market consisting of: Customers needing shopping done, and Personal Shoppers buying and delivering the products.');
        const _section_how_body_item_2 = BrandSectionItem.makeFromText('Like Uber, we broadcast Shopping requests to Personal Shoppers in the area, and facilitate order tracking, payment and live-chat between parties.');
        const _section_how_body_item_3 = BrandSectionItem.makeFromText("Any member can easily become a Personal Shopper. All orders are fulfilled directly from shops");

        const _section_how_body_items: IBrandSectionItem[] = [
            _section_how_body_item_1,
            _section_how_body_item_2,
            _section_how_body_item_3
        ];

        const _section_how_body = new BrandSectionBody('how', 'How?', _section_who_header, undefined, _section_how_body_items)
        const _section_how = new BrandSection('How', 'How?', _section_why_header, _section_how_body, undefined)

        const sections:IBrandSection[] = [
            _section_why,
            _section_how,
            _section_ups,
            _section_product,
        ]

        const meta = BrandMeta.zero();

        const router_path = '/brands/iseatu-shopping';

        const id = 'iseatu-shopping'
        const out = new Brand(id, name, from, to, header, sub_header, sections, status, app_store_urls, url, router_path, meta)

        return out;
    }

    public static makeThisElement():IBrand {
        const name = "ThisElement";
        const icon_url = 'assets/img/brands/this-element/Logo.Dark.WhiteBG1@2x.png'
        // const icon_url = 'assets/logo.png'
        const header:IBrandSectionItem = BrandSectionItem.makeFromText(name, icon_url);
        // const _subheader_text = "a mobile CRM with Invoicing for SMEs ..."
        const _subheader_text = "Tradesman's mobile office to collaborate with clients, Invoice ..."
        const sub_header = BrandSectionItem.makeFromText(_subheader_text)
        const status = new BrandStatus(false, false, undefined)
        const from = "2019";
        const to = "2021";

        const app_store_urls:IBrandAppStore[] = [];
        const url = new URL("https://www.ThisElement.com");

        const _section_product_header = BrandSectionItem.makeFromText('The Product')
        const _section_product_item_1 = new BrandSectionItem('Messages', undefined, 'assets/img/brands/this-element/1-message.png', undefined);
        const _section_product_item_2 = new BrandSectionItem('Quotations', undefined, 'assets/img/brands/this-element/2-quote.png', undefined);
        const _section_product_item_3 = new BrandSectionItem('Invoices', undefined, 'assets/img/brands/this-element/3-invoice.png', undefined);
        const _section_product_item_4 = new BrandSectionItem('Feeds', undefined, 'assets/img/brands/this-element/4-channels.png', undefined);

        const _section_product_items: IBrandSectionItem[] = [
            _section_product_item_1,
            _section_product_item_2,
            _section_product_item_3,
            _section_product_item_4,
        ];
        const _section_product_body = new BrandSectionBody('product', 'Screenshots', BrandSectionItem.makeFromText('Screenshots about the Product'), undefined, _section_product_items)
        const _section_product_footer = undefined;

        const _section_product = new BrandSection('product', 'The Product', _section_product_header, _section_product_body, _section_product_footer)

        const _section_why_header = BrandSectionItem.makeFromText('Why')
        const _why_text = 'Equip tradesmen with essential office tools they can use from their smart phone as they work with clients and colleagues';
        const _section_why_body_item_1 = BrandSectionItem.makeFromText(_why_text)
        const _section_why_body_items: IBrandSectionItem[] = [
            _section_why_body_item_1
        ];
        const _section_why_body = new BrandSectionBody('why', 'Why', _section_why_body_item_1, undefined, _section_why_body_items)
        const _section_why = new BrandSection('why', 'Why?', _section_why_header, _section_why_body, undefined)

        const _section_ups_header = BrandSectionItem.makeFromText('UPS?')
        const _section_ups_body_item_1 = BrandSectionItem.makeFromText('Core business functions (inc. Payments) integrated in a Chat interface. No contract or membership fees.')
        const _section_ups_body_items: IBrandSectionItem[] = [
            _section_ups_body_item_1
        ];
        const _section_ups_body = new BrandSectionBody('ups', 'ups?', _section_ups_body_item_1, undefined, _section_ups_body_items)
        const _section_ups = new BrandSection('ups', 'UPS?', _section_ups_header, _section_ups_body, undefined)


        const _section_who_header = BrandSectionItem.makeFromText('How?')
        const _section_how_body_item_1 = BrandSectionItem.makeFromText('Within minutes of registering, tradesmen can create quotations, invoice, chat and accept payments from their clients.');
        const _section_how_body_item_2 = BrandSectionItem.makeFromText("Tradesmen can work as team on ThisElement for free, and create unlimited projects.");
        const _section_how_body_item_3 = BrandSectionItem.makeFromText("We only charge a fee for processing Invoice payments - everything else is free.");

        const _section_how_body_items: IBrandSectionItem[] = [
            _section_how_body_item_1,
            _section_how_body_item_2,
            _section_how_body_item_3
        ];

        const _section_how_body = new BrandSectionBody('how', 'How?', _section_who_header, undefined, _section_how_body_items)
        const _section_how = new BrandSection('How', 'How?', _section_why_header, _section_how_body, undefined)

        const sections:IBrandSection[] = [
            _section_why,
            _section_how,
            _section_ups,
            _section_product,
        ]

        const meta = BrandMeta.zero();

        const router_path = '/brands/this-element';

        const id = 'this-element'
        const out = new Brand(id, name, from, to, header, sub_header, sections, status, app_store_urls, url, router_path, meta)

        return out;
    }
}