<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!--  -->
  <!-- Auth Dialog -->
  <!--  -->

<!--
  <TransitionRoot appear :show="showAuthDialog" as="template">
    <Dialog as="div" @close="auth.handleHideAuthDialog($event)">
      <div class="fixed inset-0 z-10 overflow-y-auto headlessui-dialogu-overlay">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0"/>
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
          >
            <div
                class="inline-block w-full max-w-md p-6AAA pl-6 pr-6 pb-6 pt-1 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
            >
&lt;!&ndash;
              <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
              >
                Login
              </DialogTitle>
              &ndash;&gt;
              <DialogTitle
                  as="div"
                  class="text-lg font-medium leading-6 text-gray-900 flex"
              >
                <div class="flex-1 w-2/3">

                </div>
                <div class="flex">
                  <a href="#" class="" @click="auth.handleHideAuthDialog($event)">
                    <XCircleIcon class="h-10 w-10 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                  </a>
                </div>
              </DialogTitle>

              <div class="mt-2 auth-forms" >
                <div class="min-h-screenAAA flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                  <div class="max-w-md w-full space-y-8">

                    <div class="auth-form-login" v-if="showLoginFormAuthDialog">

                      <div class="auth-form-header">
                        <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />
                        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900 hidden">
                          Sign in to your account
                        </h2>
                        <p class="mt-2 text-center text-sm text-gray-600 hidden">
                          Or
                          {{ ' ' }}
                          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                            start your 14-day free trial
                          </a>
                        </p>
                      </div>

                      <div class="auth-form-body">
                        <form class="mt-8 space-y-6" @submit.prevent="auth.forms.login.submitLogin($event)" actionAAA="" name="login_form" methodAAA="POST">
                          <input type="hidden" name="remember" value="true" />
                          <div class="rounded-md shadow-sm -space-y-px">
                            <div>
                              <label for="email-address" class="sr-only">Email address</label>
                              <input id="email-address" v-model="auth.forms.login.email"  name="email" type="email" autocomplete="email" requiredAAA=""
                                     class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                     placeholder="Email address"
                                     :class="{'is-invalid': auth.forms.login.isSubmitted && auth.v$.login.email.$error }"
                                     @change="auth.v$.login.email.$touch()" />
                              <div v-if="auth.forms.login.isSubmitted && auth.v$.login.email.$error"
                                   class="error-messages text-red-400 mb-2">
                                <span v-if="auth.v$.login.email.required || auth.v$.login.email.email"> ↑ Valid Email required</span>
&lt;!&ndash;                                <span v-else-if="!$v.userForm.confirmPassword.sameAsPassword">Passwords should be matched</span>&ndash;&gt;
                              </div>
                            </div>
                            <div>
                              <label for="password" class="sr-only">Password</label>
                              <input id="password" v-model="auth.forms.login.password"  name="password" type="password" autocomplete="current-password" requiredAAA=""
                                     class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                     placeholder="Password"
                                     :class="{'is-invalid': auth.forms.login.isSubmitted && auth.v$.login.password.$error }"
                                     @change="auth.v$.login.email.$touch()" />
                              <div v-if="auth.forms.login.isSubmitted && auth.v$.login.password.$error"
                                   class="error-messages text-red-400 mb-2">
                                <span v-if="auth.v$.login.password.required"> ↑ Valid Password required</span>
                                &lt;!&ndash;                                <span v-else-if="!$v.userForm.confirmPassword.sameAsPassword">Passwords should be matched</span>&ndash;&gt;
                              </div>
                            </div>
                          </div>

                          <div class="flex items-center justify-between">
                            &lt;!&ndash;
                                                      <div class="flex items-center">
                                                        <input id="remember_me" name="remember_me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                                        <label for="remember_me" class="ml-2 block text-sm text-gray-900">
                                                          Remember me
                                                        </label>
                                                      </div>
                                                      &ndash;&gt;

                            <div class="text-sm pl-2">
                              <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"
                                 @click="auth.showSignUpForm($event)">
                                Sign Up
                              </a>
                            </div>

                            <div class="text-sm">
                              <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"
                                 @click="auth.showForgotPasswordForm($event)">
                                Forgot your password?
                              </a>
                            </div>
                          </div>

                          <div>
                            <button type="submit"
                                    class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    @clickAAA="auth.submitLoginForm($event)"
                            >
                            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                              <LockClosedIcon class="h-5 w-5 text-indigo-100 group-hover:text-indigo-400" aria-hidden="true" />
                            </span>
                              Sign in
                            </button>
                          </div>
                        </form>
                      </div>

                    </div>

                    <div class="auth-form-sign-up" v-else-if="showSignUpFormAuthDialog">

                      <div class="auth-form-header">
                        <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />
                        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900 hidden">
                         Create your account
                        </h2>
                        <p class="mt-2 text-center text-sm text-gray-600 hidden">
                          Or
                          {{ ' ' }}
                          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                            start your 14-day free trial
                          </a>
                        </p>
                      </div>

                      <div class="auth-form-body">
                        <form class="mt-8 space-y-6" @submit.prevent="auth.forms.signup.submitSignUp($event)" actionAAA="" name="signup_form" methodAAA="POST">
&lt;!&ndash;                          <input type="hidden" name="remember" value="true" />&ndash;&gt;
                          <div class="rounded-md shadow-sm -space-y-px">
                            <div>
                              <label for="sign-up-name" class="sr-only">Full Name</label>
                              <input id="sign-up-name" v-model="auth.forms.signup.name" name="name" type="text" autocomplete="name" requiredAAA="" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                     placeholder="Your full name"
                                     :class="{'is-invalid': auth.forms.signup.isSubmitted && auth.v$.signup.name.$error }"
                                     @change="auth.v$.signup.name.$touch()"
                              />
                              <div v-if="auth.forms.signup.isSubmitted && auth.v$.signup.name.$error"
                                   class="error-messages text-red-400 mb-2">
                                <span v-if="auth.v$.signup.name.required"> ↑ Name required</span>
                                &lt;!&ndash;                                <span v-else-if="!$v.userForm.confirmPassword.sameAsPassword">Passwords should be matched</span>&ndash;&gt;
                              </div>
                            </div>
                            <div>
                              <label for="sign-up-email-address" class="sr-only">Email address</label>
                              <input id="sign-up-email-address" v-model="auth.forms.signup.email"  name="email" type="email" autocomplete="email" requiredAAA=""
                                     class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-mdAAA focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                     placeholder="Email address"
                                     :class="{'is-invalid': auth.forms.signup.isSubmitted && auth.v$.signup.email.$error }"
                                     @change="auth.v$.signup.email.$touch()"
                              />
                              <div v-if="auth.forms.signup.isSubmitted && auth.v$.signup.email.$error"
                                   class="error-messages text-red-400 mb-2">
                                <span v-if="auth.v$.signup.email.required"> ↑ Valid Email required</span>
                                &lt;!&ndash;                                <span v-else-if="!$v.userForm.confirmPassword.sameAsPassword">Passwords should be matched</span>&ndash;&gt;
                              </div>
                            </div>
                            <div>
                              <label for="sign-up-password" class="sr-only">Password</label>
                              <input id="sign-up-password" v-model="auth.forms.signup.password"  name="password" type="password" autocomplete="current-password" requiredAAA=""
                                     class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                     placeholder="Password"
                                     :class="{'is-invalid': auth.forms.signup.isSubmitted && auth.v$.signup.password.$error }"
                                     @keyup="auth.forms.signup.passwordStrength($event, auth.forms.signup.settings.styles)"
                              />
                              <div v-if="auth.forms.signup.isSubmitted && auth.forms.signup.settings.styles.show" class="error-messages text-red-400 mb-2">

                                <div>
                                  <span :class="{'text-red-400': auth.forms.signup.settings.styles.password.weak, 'text-yellow-500': auth.forms.signup.settings.styles.password.fair, 'text-green-600': auth.forms.signup.settings.styles.password.strong}">
                                    ↑ {{auth.forms.signup.settings.styles.password.message.text}}
                                  </span>
                                  <div class="inline ">
                                    <password-info class="inline pl-4"></password-info>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="flex items-center justify-between">
                              &lt;!&ndash;
                              <div class="flex items-center pl-2">
                                <input id="sign-up-agree" name="singup_agree" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                <label for="sign-up-agree" class="ml-2 block text-sm text-gray-900">
                                  I Agree
                                </label>
                              </div>
                              &ndash;&gt;
                            <div class="flex items-start ml-2">

                              <div class="flex items-center h-5">
                                <input id="sign-up-agree" v-model="auth.forms.signup.agree" name="singup_agree" type="checkbox" class=" focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded" />
&lt;!&ndash;                                <input id="sign-up-agree" v-model="form_signup.agree" name="singup_agree" type="checkbox" class="appearance-noneAAA checked:bg-blue-600 checked:border-transparent focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-black rounded" />&ndash;&gt;
                              </div>
                              <div class="ml-3 text-sm">
                                <label for="sign-up-agree" class="font-medium text-gray-700">I'm 18+ and agree to </label>
                              </div>
                              <div class="ml-3 text-sm underline">
                                <p class="text-gray-500"><a href="#" @click="terms.conditions($event)">Terms & Conditions</a> </p>
                              </div>

                            </div>

&lt;!&ndash;                            <div>
                              <input type="text" placeholder="testing ..." :disabled="form_signup.agree ? 'disabled' : false">
                            </div>&ndash;&gt;


                          </div>

                          <div class="flex items-center justify-between">
                            &lt;!&ndash;
                                                      <div class="flex items-center">
                                                        <input id="remember_me" name="remember_me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                                        <label for="remember_me" class="ml-2 block text-sm text-gray-900">
                                                          Remember me
                                                        </label>
                                                      </div>
                                                      &ndash;&gt;

                            <div class="text-sm pl-2">
                              <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"
                                 @click="auth.showLoginForm($event)">
                                Login
                              </a>
                            </div>

&lt;!&ndash;                            <div class="text-sm">
                              <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"
                                 @click="auth.showForgotPasswordForm($event)">
                                Forgot your password?
                              </a>
                            </div>
                            &ndash;&gt;
                          </div>

                          <div>
                            <button type="submit"
                                    :disabled="auth.forms.signup.agree ? false : 'disabled'"
                                    class="disabled:opacity-50 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                              <AcademicCapIcon class="h-5 w-5 text-indigo-100 group-hover:text-indigo-400" aria-hidden="true" />
                            </span>
                              Sign Up
                            </button>
                          </div>
                        </form>
                      </div>

                    </div>
                    <div class="auth-form-forgot-password" v-else>

                      <div class="auth-form-header">
                        <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />
                        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900 hidden">
                          Forgot Password
                        </h2>
                        <p class="mt-2 text-center text-sm text-gray-600 hidden">
                          Or
                          {{ ' ' }}
                          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                            start your 14-day free trial
                          </a>
                        </p>
                      </div>

                      <div class="auth-form-body">
                        <form class="mt-8 space-y-6" @submit.prevent="auth.forms.forgot.submitForgotPassword($event)" actionAAA="" name="forgot_password_form" methodAAA="POST">
&lt;!&ndash;                          <input type="hidden" name="remember" value="true" />&ndash;&gt;
                          <div class="rounded-md shadow-sm -space-y-px">
                            <div>
                              <label for="current-email-address" class="sr-only">Email address</label>
                              <input id="current-email-address" v-model="auth.forms.forgot.email"  name="current_email" type="email" autocomplete="email" requiredAAA=""
                                     class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                     placeholder="Email address"
                                     :class="{'is-invalid': auth.forms.forgot.isSubmitted && auth.v$.forgot.email.$error }"
                                     @change="auth.v$.forgot.email.$touch()"
                              />
                              <div v-if="auth.forms.forgot.isSubmitted && auth.v$.forgot.email.$error"
                                   class="error-messages text-red-400 mb-2">
                                <span v-if="auth.v$.forgot.email.required"> ↑ Valid Email required</span>
                                &lt;!&ndash;                                <span v-else-if="!$v.userForm.confirmPassword.sameAsPassword">Passwords should be matched</span>&ndash;&gt;
                              </div>
                            </div>
                          </div>

                          <div class="flex items-center justify-between">
                            &lt;!&ndash;
                                                      <div class="flex items-center">
                                                        <input id="remember_me" name="remember_me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                                        <label for="remember_me" class="ml-2 block text-sm text-gray-900">
                                                          Remember me
                                                        </label>
                                                      </div>
                                                      &ndash;&gt;

                            <div class="text-sm pl-2">
                              <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"
                                 @click="auth.showLoginForm($event)">
                                Login
                              </a>
                            </div>

&lt;!&ndash;                            <div class="text-sm">
                              <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"
                                 @click="auth.showForgotPasswordForm($event)">
                                Forgot your password?
                              </a>
                            </div>
                            &ndash;&gt;
                          </div>

                          <div>
                            <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    @clickAAA="auth.submitForgotPasswordForm($event)"
                              >
                            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                              <AtSymbolIcon class="h-5 w-5 text-indigo-100 group-hover:text-indigo-400" aria-hidden="true" />
                            </span>
                              Forgot Password
                            </button>
                          </div>


                        </form>
                      </div>

                    </div>
                  </div>
                </div>

              </div>

              <div class="mt-4 flex hidden">
                <div class="flex-1">
                  <button
                      type="button"
                      class="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-gray-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      @click="auth.handleHideAuthDialog($event)"
                  >
                    Cancel
                  </button>
                </div>
                <div class="flex">
                  <button
                      type="button"
                      class="inline-flex justify-center px-4 py-2 text-sm font-medium text-yellow-100 bg-blue-500 border border-transparent rounded-md hover:bg-blue-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-700"
                      @click="auth.submitLoginForm($event)"
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  -->

  <!--  -->
  <Disclosure as="nav" class="bg-gray-800 sticky top-0 z-1 header" v-slot="{ open }">
<!--  <Disclosure as="nav" class="bg-gray-800 sticky top-0" >-->
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton as="button"
                            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            @click="toggleDisclosure($event, open)"
                            @keydown.enter="keyPressToggleDisclosure($event, open)"
                            @keydown.space="keyPressToggleDisclosure($event, open)"
          >
            <span class="sr-only">Open main menu</span>
<!--            {{open ? 'O': 'C'}} {{menuIsOpen ? 'MO': 'MC'}}-->
            <XIcon v-if="menuIsOpen" class="block h-6 w-6" aria-hidden="true"/>
            <MenuIcon v-else class="block h-6 w-6" aria-hidden="true"/>
          </DisclosureButton>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <!--
            <img class="block lg:hidden h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                 alt="gg Plain"/>
            <img class="hidden lg:block h-8 w-auto"
                 src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg" alt="gg Plain"/>
            -->

<!--            <ChipIcon class="h-8 w-8 text-white" aria-hidden="true" />-->

            <router-link to="/" key="home" class="">
              <span class="text-xl text-white hover:underline">Omneris</span>
            </router-link>

          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">
              <!--              <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>-->
              <router-link
                  v-for="item in navigation" :key="item.name" :to="item.href"
                  :class="[item.id === nav_id ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']"
                  :aria-current="item.id === nav_id ? 'page' : undefined"
                  @click="setCurrentItem(item)"
              >
                {{ item.name }}
              </router-link>

            </div>
          </div>
        </div>


        <div class="sm:flex items-center justify-end sm:flex-1 lg:w-0AAA">

          <!--
          <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <AtSymbolIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            say Hi
          </button>
          -->

          <say-hi></say-hi>

        </div>


        <div class="absolute inset-y-0 right-0 flex items-center pr-2AAA sm:static sm:inset-auto sm:ml-6 sm:pr-0">

          <div class="block sm:hidden text-white">

            <div class="space-x-4" >
              <!--
              <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <AtSymbolIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                say Hi
              </button>
              -->

              <say-hi></say-hi>

              <!--

              <button
                  class="ml-5AAA mr-5AA bg-gray-300 py-2 px-6 border border-gray-800 rounded-md shadow-sm text-sm leading-6 font-medium text-blue-600 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  @click="void(0);"
              >
                <span class="sr-only">Login</span>
                <div class="flex flex-row items-center space-x-2">
                  &lt;!&ndash;                <LockClosedIcon class="h-3 w-3" aria-hidden="true" />&ndash;&gt;
                  <span>Login</span>
                </div>
                &lt;!&ndash;            <BellIcon class="h-6 w-6" aria-hidden="true" />&ndash;&gt;
              </button>
              -->
            </div>
          </div>

          <div class="hidden md:block">
            <!-- Profile dropdown -->
<!--            <div class="space-x-4" >
              <button
                  class="ml-5AAA mr-5AA bg-gray-300 py-2 px-6 border border-gray-800 rounded-md shadow-sm text-sm leading-6 font-medium text-blue-600 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  @click="auth.goToLogin($event)"
              >

                <span class="sr-only">Login</span>
                <div class="flex flex-row items-center space-x-2">
                  &lt;!&ndash;                <LockClosedIcon class="h-3 w-3" aria-hidden="true" />&ndash;&gt;
                  <span>Login</span>
                </div>
                &lt;!&ndash;            <BellIcon class="h-6 w-6" aria-hidden="true" />&ndash;&gt;
              </button>
              <button
                  class="ml-5AAA mr-5AA bg-blue-600 py-2 px-6 border border-gray-800 rounded-md shadow-sm text-sm leading-6 font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  @click="auth.goToSignUp($event)"
              >

                <span class="sr-only">Sign Up</span>
                <div class="flex flex-row items-center space-x-2">
                  &lt;!&ndash;                <LockClosedIcon class="h-3 w-3" aria-hidden="true" />&ndash;&gt;
                  <span>Sign Up</span>
                </div>
                &lt;!&ndash;            <BellIcon class="h-6 w-6" aria-hidden="true" />&ndash;&gt;
              </button>

            </div>-->
          </div>

        </div>
      </div>
    </div>
    <div class="" v-show="menuIsOpen">
      <DisclosurePanel class="sm:hiddenAAA" AAAv-show="openRef" static>
        <div class="px-2 pt-2 pb-3 space-y-1">
          <!--        <a v-for="item in navigation" :key="item.name" :href="item.href"
                     :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']"
                     :aria-current="item.current ? 'page' : undefined">{{ item.name }} :) </a>
                  -->
          <router-link
              v-for="item in navigation" :key="item.name" :to="item.href"
              :class="[item.id === nav_id ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']"
              :aria-current="item.id == nav_id ? 'page' : undefined"
              @click="setCurrentItem(item, open)"
          >
            {{ item.name }}
          </router-link>
        </div>
      </DisclosurePanel>
    </div>

  </Disclosure>

</template>

<script lang="ts">

import {defineComponent, ref, computed, reactive, watch, onMounted, onUnmounted} from 'vue'

import {useStore} from "vuex";

import {
  Dialog, DialogOverlay, DialogTitle,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'

/*
import {
  BellIcon, MenuIcon, XIcon, LockClosedIcon, LogoutIcon,
  XCircleIcon, AcademicCapIcon, AtSymbolIcon,
  ChipIcon
} from '@heroicons/vue/outline'
*/

import {MenuIcon, XIcon, AtSymbolIcon} from '@heroicons/vue/outline'

import {INavItem, NavItem} from "@/types/NavItem.interface";
// import {useAppSettings} from "@/main";
// import {AnyObject, SomeObject} from "@/types/AppTypes";
import {useRouter} from "vue-router";
import {useAppSettings} from "@/main";
import SayHi from "@/components/SayHi.vue";


const default_nav_item: INavItem = new NavItem('home', 'Home', '/', true);
const navigation:Record<string, INavItem> = {
  'home': new NavItem('home', 'Home', '/', true),
  'brands': new NavItem('brands', 'Brands', '/brands', false),
  'about': new NavItem('about', 'About', '/about', false),
  // new NavItem('sample', 'Sample', '/sample', false),
  // new NavItem('auth', 'Login', '/login', false),
  // new NavItem('auth_login', 'Login', '/user/login', false),
  // new NavItem('auth_signup', 'Sign Up', '/user/signup', false),
  // new NavItem('auth_forgot', 'Forgot', '/user/forgot', false),
}

function getCurrent(nav_items: Record<string, INavItem>): INavItem {
  for (const [key, value] of Object.entries(nav_items)) {
    if (value.current) {
      return value;
    }
  }
  return default_nav_item;
}

export default defineComponent({
  name: 'AppNavBar',
  components: {
    SayHi,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    // Menu,
    // MenuButton,
    // MenuItem,
    // MenuItems,
    // BellIcon,
    // AtSymbolIcon,
    MenuIcon,
    XIcon,
    // LogoutIcon,
    // ChipIcon,
    // Dialog,
    // DialogOverlay,
    // DialogTitle,
    // //DialogDescription,
    // TransitionRoot,
    // TransitionChild,
    // PasswordInfo,
    // XCircleIcon,
    // AcademicCapIcon,
    // AtSymbolIcon,
    // LockClosedIcon,
  },
  setup() {

    //const store = useStore();
    const {stg} = useAppSettings()
    const router = useRouter();
    // const open = ref(false)

    const isAuthenticated = computed(() => {
          return false
        }
    );
    const terms = reactive({
      conditions: () => {
        console.log('terms.conditions');
      },
      cookies: () => {
        console.log('terms.cookies');
      },
      privacy: () => {
        console.log('terms.privacy');
      },
    })

    //
    let item: INavItem = getCurrent(navigation);
    // const nav_id = ref(item.id);
    const nav_id = computed({
      get():string {
        const value = stg.nav.selected_id;
        console.log('GET.nav_id: ', value)
        return value
      },
      set(v:string) {
        stg.nav.selected_id = v
      }
    })

    const forceOpen = ref(false);
    const menuIsOpen = ref(false);
    //

    const setCurrentItem = (item: INavItem, open:boolean) => {
      //this.current_item = item;
      nav_id.value = item.id;
      // setCurrentBy(item, this.navigation)
      // console.log(nav_id.value);
      router.push(item.href);
      // open.value = false;
      // dc.open = false;
      menuIsOpen.value = false;
      // console.log(nav_id.value, ' - open:', open, ', menuIsOpen: ', menuIsOpen.value);
    }
    const keyPressToggleDisclosure = (event:KeyboardEvent, open:boolean) => {
      // console.log('key: ', event.code);
      toggleDisclosure(event, open);
    };
    const toggleDisclosure = (event:any, open:boolean) => {
      //console.log('BEFORE.toggleDisclosure - open:', open, ', menuIsOpen: ', menuIsOpen.value);
      const isOpening = !open;
      const isMenuOpening = !menuIsOpen.value;
      const same = isOpening === isMenuOpening;
      setTimeout(()=> {
        if (same) {
          menuIsOpen.value = isMenuOpening;
        }else{
          if (menuIsOpen.value === isOpening) {
            menuIsOpen.value = !isOpening;
          }else{
            menuIsOpen.value = isOpening;
          }
        }
        // console.log('AFTER.toggleDisclosure - open:', open, ', menuIsOpen: ', menuIsOpen.value);
      }, 10)
      //openMenu.value = false;
    }

    onMounted(() => {
      nav_id.value = item.id;
      stg.nav.selected_id = item.id;
    })

    onUnmounted(() => {
      return
    });

    return {
      navigation,
      setCurrentItem,
      toggleDisclosure,
      keyPressToggleDisclosure,
      forceOpen,
      menuIsOpen,
      terms,
      nav_id,
      isAuthenticated,
      nav: computed(() => stg.nav)
    }
  },
})
</script>


<style>

.headerAAA {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 20;
}
.auth-forms {

}
.auth-forms .is-invalid {
  border-color: red;
}
.auth-forms .error-messages {
  //color: red;
}
</style>