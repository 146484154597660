

import {defineComponent, reactive} from "vue";
import { useRoute } from 'vue-router';

export default defineComponent({
  name: "NotFoundPage",
  setup() {

    const route = useRoute();
    // const router = useRouter();
    // const routeHash = useRouteHash()

    const msg = reactive({
      code: 404,
      title: 'Error',
      user_msg: 'Page not found'
    })
    return {
      msg,
      // route,
    }
  }
})
