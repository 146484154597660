

import {defineComponent, onMounted, onUnmounted, ref, watch} from "vue";
import {useAppSettings} from "@/main";
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption,
} from '@headlessui/vue'
import {WatchStopHandle} from "@vue/runtime-core";
import {useRoute, useRouter} from "vue-router";

import {nth} from 'lodash/fp';
import {IBrand} from "@/types/Brand.interface";

export default defineComponent({
  name: "BrandsPage",
  components: {
    // RadioGroup,
    // RadioGroupLabel,
    //// RadioGroupDescription,
    // RadioGroupOption,
  },
  setup(props, ctx) {

    const router = useRouter();
    const route = useRoute();

    const {stg} = useAppSettings()
    const brands = stg.brands;
    const initialBrandSelected = 'velosofi';
    const selected = ref(brands[initialBrandSelected])
    let stopWatchingSelected:WatchStopHandle;
    let stopWatchingRoute:WatchStopHandle;

    const handleSelectBrand = (event:Event, brand:IBrand) => {
      selected.value = brands[brand.id];

      return
    }

    onMounted(() => {
      stopWatchingSelected = watch(selected, (value, oldValue) => {
        //console.log('watch.selected: ', value.id);
        router.push(value.router_path);
      })
      stopWatchingRoute = watch(route, (value, oldValues) => {
        // console.log('stopMainWatch: ', newValues.path)
        processPath(value.path);
      })
      //
      processPath(route.path);
    })

    onUnmounted(() => {
      stopWatchingSelected();
      stopWatchingRoute();
    })

    const goToSelectedPath = () => {
      router.push(selected.value.router_path);
    }

    const processPath = (path:string) => {

      if (stg.nav.path_levels.level_1?.toLowerCase() !== 'brands') {
        return;
      }

      const brand_page_type = stg.nav.path_levels.level_2
      if (!brand_page_type) {
        selected.value = brands[initialBrandSelected];
        goToSelectedPath();
        return;
      }
      selected.value = brands[brand_page_type] || brands[initialBrandSelected];

      /*
      const parts = path.split('/').filter((s) => !!s)
      const n_parts = parts.length;
      let db_selected = '';
      console.log(path, ' - 0: ', nth(0, parts), ', 1: ', nth(1, parts), ', db_selected: ', db_selected);
      */
    }

    const ensureNavSelectedIsBrand = (path_part:string) => {
      if (path_part !== 'brands') {
        return
      }
      console.log('brands nav_id')
      stg.nav.selected_id = path_part;
    }

    return {
      brands,
      selected,
      handleSelectBrand
    }
  }
})
