
import {IBrand, Brand} from "@/types/Brand.interface";

const velosofi:IBrand = Brand.makeVeloSofi();
const iseatu_marketplace: IBrand = Brand.makeIseatuMarketplace();
const iseatu_shopping: IBrand = Brand.makeIseatuPersonalShopping();
const this_element: IBrand = Brand.makeThisElement();

const out:Record<string, IBrand> = {}
out[velosofi.id] = velosofi;
out[iseatu_marketplace.id] = iseatu_marketplace;
out[iseatu_shopping.id] = iseatu_shopping;
out[this_element.id] = this_element;

export const ALL_BRANDS:Record<string, IBrand> = out;