<template>
  <div class="say-hi">
    <div class="space-x-4">
      <button type="button"
              class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="openModal($event)"
      >
        <AtSymbolIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        say Hi
      </button>
    </div>

    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
            >
              <div
                  class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-whiteAAA shadow-xl rounded-2xl AAABBBAAA bg-gradient-to-r from-green-400 to-yellow-200"
              >
                <DialogTitle
                    as="div"
                    class="text-lg font-medium leading-6 text-gray-900AAA text-white flex"
                >
                  <div class="flex-1">
                    <span v-if="enabled">Contact us</span>
                    <span v-else>Reveal contact address</span>
                  </div>
                  <div class="text-gray-600">
                    <button @click="closeModal($event)"><XIcon class="m-1 h-5 w-5 " aria-hidden="true" /></button>
                  </div>
                </DialogTitle>
                <div class="mt-2">

                  <div class="py-12 text-center " v-if="enabled">
                    <p class="text-2xl text-blue-500 underline">
                      <a :href="`mailto:${makeAddress()}?subject=Hi`">{{makeAddress()}}<CursorClickIcon class="inline-block ml-2 h-5 w-5 " aria-hidden="true" /> </a>
                    </p>
                  </div>

                  <div class="py-12" v-else>
                    <SwitchGroup as="div" class="flex-1">
                      <div class="flex flex-row items-center justify-center">
                        <SwitchLabel class="mr-2 text-lg text-white inline-block">
                          <ArrowNarrowRightIcon class="-ml-1 mr-2 h-5 w-5 animate-bounce" aria-hidden="true" />
                        </SwitchLabel>
                        <Switch
                            v-model="enabled"
                            :class='enabled ? "bg-blue-600" : "bg-gray-200"'
                            class="relative inline-flex items-center h-6 transition-colors rounded-full w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                                  <span
                                      :class='enabled ? "translate-x-6" : "translate-x-1"'
                                      class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full"
                                  />
                        </Switch>
                      </div>
                    </SwitchGroup>

                  </div>

                  <p class="text-sm text-gray-500 hidden">
                    Your payment has been successfully submitted. We’ve sent your
                    an email with all of the details of your order.
                  </p>
                </div>

                <div class="mt-4 hidden">
                  <button
                      type="button"
                      class="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      @click="closeModal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script lang="ts">

import {defineComponent, onMounted, onUnmounted, ref, watch} from "vue";
import {

  Switch,
  SwitchGroup,
  SwitchLabel,

  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue';

import {AtSymbolIcon, ArrowNarrowRightIcon, CursorClickIcon, XIcon} from '@heroicons/vue/outline'
import {WatchStopHandle} from "@vue/runtime-core";

export default defineComponent({
  name: "SayHi",
  components: {
    XIcon,
    CursorClickIcon,
    ArrowNarrowRightIcon,
    AtSymbolIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  setup() {

    const isOpen = ref(false);
    const enabled = ref(false);
    const makeAddress = ():string => {
      const p1 = 'Al';
      const p2 = 'Omneris'
      const p3 = '.com';
      const out = [p1, '@', p2, p3].join('');
      return out;
    }
    const closeModal = () => {
      isOpen.value = false
    }
    const openModal = () => {
      isOpen.value = true
    }

    let stopWatchingIsOpen:WatchStopHandle;
    onMounted(() => {
      stopWatchingIsOpen = watch(isOpen, (open, oldValue) => {
        if (!open) {
          setTimeout(() => {
            enabled.value = false;
          }, 200);
        }
      });
    })

    onUnmounted(() => {
      stopWatchingIsOpen();
    })

    return {
      enabled,
      isOpen,
      openModal,
      closeModal,
      makeAddress
    }
  }
})
</script>

<style>

</style>