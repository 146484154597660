<template>
  <div class="brand-page">

    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl space-y-3 py-3">
      <span class="block">{{brand.name}}</span>
      <span class="block text-indigo-600">{{brand.sub_header?.text}}</span>
    </h2>


    <div class="" v-for="section in brand.sections" :key="section.id">
      <div class="text-center pt-5">
        <h2 class="text-base text-black-600 text-lg font-semibold tracking-wide uppercase">{{section.name}}</h2>
      </div>

      <div class="">
        <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto" v-for="(item, index) in section.body.items" :key="index">
          {{item.text}}
          <span class="flex items-center justify-center">
            <img :src="require(`../../${item.img}`)" alt="" v-if="item.img" class="w-3/4 md:2/3">
          </span>
        </p>
      </div>

    </div>

  </div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import {useAppSettings} from "@/main";

export default defineComponent({
  name: "BrandThisElementPage",
  components: {

  },
  setup(props, ctx) {

    const {stg} = useAppSettings();
    const brand = stg.brands['this-element']

    return {
      brand
    }
  }
})
</script>

<style>

</style>