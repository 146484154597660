
import {computed, createApp, reactive, ref} from 'vue'
import App from './App.vue'
import router, {ALL_ROUTES, PathLevels} from './router'
import store from './store'
import './assets/tailwind.css'
import { ALL_BRANDS } from "@/utils/brand-utils";
import { IBrand } from "@/types/Brand.interface";
import { stringify } from "postcss";
import {IMainSettings, IPageSettings, AppProfileTypes, INavSettings} from "@/types/AppTypes";
import {APP_PROFILE} from "@/utils/app-utils"


const brands:Record<string, IBrand> = ALL_BRANDS

const nav_state: INavSettings = {
    selected_id: 'home',
    path_levels: PathLevels.zero()
}

const page_state:IPageSettings = {
    'home': {
        name: 'Home',
        'sections': {
            'portfolio': {
                id: 'portfolio',
                name: 'start-up ventures',
                header: "disrupt the market bullies",
                body: ["our brands focus on solving one core problem for the user, way better than market leaders:"],
                footer: '',
                urls: [],
                items: []
            }
        }
    },
    'about': {
        'name': 'About',
        // 'header': 'About...',
        'body': '',
        'sections': {
            'who': {
                'id': 'who', name: '', header: '', body: [], footer: '', urls: [],
                items: [
                    {
                        id: 'al', name: 'Al Kola', header: 'Co-Founder / CEO', body: ['Created iSeatU brands, ThisElement, and co-created velosofi.', 'BSc Computer Science'], img: 'assets/img/team/al-kola.jpg', urls: [
                            {id: 'linkedin', name:'LinkedIn', img: 'assets/img/vendor/LI-Bug.original.svg', url: 'https://www.linkedin.com/in/al-kola-43502816/'}
                        ], items: []
                    },
                    {
                        id: 'aleks', name: 'Aleks Kola', header: 'Co-Founder', body: [
                            'Designed velosofi algorithms, co-created velosofi tech-stack.',
                            'BSc Computer Science, MSc Applied Business Informatics '
                        ],
                        img: 'assets/img/team/aleks-kola.jpg', urls: [
                            {id: 'linkedin', name: 'LinkedIn', img: 'assets/img/vendor/LI-Bug.original.svg', url: 'https://www.linkedin.com/in/aleks-kola-885b9814/'}
                        ], items: []
                    }
                ]
            },
            'contact': {
                id: 'contact', name: 'Contact', header: 'say Hi', body: [], item_type: 'button', urls: [], items: []
            }
        }
    },
    brands: {
        name: 'Brands',
        sections: {}
    }
}

const main_settings = reactive<IMainSettings>({
    brands: brands,
    routes: ALL_ROUTES,
    nav: nav_state,
    page: page_state
});

export const useAppSettings = () => {
    return  {
        stg: main_settings
    }
}

const app = createApp(App);

function initMain() {

    //
    // _APP_PROFILE = AppProfileTypes.test_public_sandbox;
    // APP_PROFILE = _APP_PROFILE;
    //

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (APP_PROFILE !== AppProfileTypes.prod) {
        window.omneris = {
            app: app,
            context: app._context,
            // store: app._context.provides.store,
            stg: main_settings
        }
        //console.log('omneris.init: ');
    }
}

setTimeout( () => {
    console.log('initMain: ');
    initMain();
}, 100)

app.use(store).use(router)
app.mount('#app')