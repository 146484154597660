<template>

  <div class="brands-page divide-y-3 divide-green-500 ">

    <div class="lg:flex lg:items-center lg:justify-between bg-white stickyAAA top-0AAA border-b pb-4 ">
      <div class="flex-1 min-w-0">
<!--        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Back End Developer
        </h2>-->
        <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6 items-center justify-center">

<!--          <div class="mt-2 flex items-center text-sm text-gray-500" v-for="brand in brands" :key="brand.id">
            <router-link :to="brand.router_path" :key="brand.id"><span class="">{{brand.name}}</span></router-link>
          </div>-->

          <div class="w-full px-4 py-2AAA">
            <div class="w-full max-w-md mx-auto">
              <div AAAvAmodel="selected" AAAdisabled="false"  >
                <label class="sr-only">Brands</label>
                <div class="md:space-x-2 md:flex md:flex-row AAAsm:flex-wrap grid grid-cols-2 gap-x-3 gap-y-2 gap-y-10AAA ">
                  <div
                      as="template"
                      v-for="(brand, k) in brands"
                      :key="k"
                      :value="brand"
                      @click="handleSelectBrand($event, brand)"
                  >
                      <div
                          :class="[
                  selected.id === k
                    ? 'ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60'
                    : '',
                  selected.id === k ? 'bg-blue-900 bg-opacity-75 text-white ' : 'bg-white ',
                ]"
                          class="relative flex-1 px-2 sm:py-4 py-2 rounded-lg shadow-lg cursor-pointer focus:outline-none"
                      >
                        <div class="flex items-center justify-between w-full" >

                          <router-link :to="brand.router_path" :key="brand.id">

                            <div class="flex items-center">
                              <div class="text-sm">

                                <label
                                    as="p"
                                    :class="selected.id === k ? 'text-white' : 'text-gray-900'"
                                    class="font-medium"
                                >
                                  <div class="flex flex-row space-x-2 items-center">
                                    <div class="absoluteAAA flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500AAA bg-white borderAAA text-whiteAAA ">
                                      <img v-if="brand.header.img" :src="require(`../../${brand.header.img}`)" class="h-8 w-8 brand-image" aria-hidden="true" />
                                    </div>
                                    <div class="flex">
                                      <span>{{ brand.name }}</span>
                                    </div>
                                  </div>
                                </label>

<!--                                <RadioGroupDescription
                                    as="span"
                                    :class="checked ? 'text-sky-100' : 'text-gray-500'"
                                    class="inline"
                                >
                                  <span> {{ brand.from }}/{{ brand.to }}</span>
                                  <span aria-hidden="true"> &middot; </span>
                                  <span>{{ brand.header.text }}</span>
                                </RadioGroupDescription>-->

                              </div>
                            </div>
<!--                            <div v-show="checked" class="flex-shrink-0 text-white">
                              <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none">
                                <circle
                                    cx="12"
                                    cy="12"
                                    r="12"
                                    fill="#fff"
                                    fill-opacity="0.2"
                                />
                                <path
                                    d="M7 13l3 3 7-7"
                                    stroke="#fff"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                              </svg>
                            </div>-->
                          </router-link>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <!--
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <BriefcaseIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Full-time
          </div>
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <LocationMarkerIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Remote
          </div>
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <CurrencyDollarIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            $120k &ndash; $140k
          </div>
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Closing on January 9, 2020
          </div>
          -->
        </div>
      </div>

    </div>

    <section class="mb-auto flex-1 overflow-y-auto overflow-x-hidden ">
      <div class="max-w-7xl mx-auto pb-6 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="px-4 pb-6 pt-2 sm:px-0 ">
          <div class="h-full flex-1 h-96AAA " >
            <router-view/>
          </div>
        </div>
        <!-- /End replace -->
      </div>
    </section>

  </div>

</template>

<script lang="ts">

import {defineComponent, onMounted, onUnmounted, ref, watch} from "vue";
import {useAppSettings} from "@/main";
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption,
} from '@headlessui/vue'
import {WatchStopHandle} from "@vue/runtime-core";
import {useRoute, useRouter} from "vue-router";

import {nth} from 'lodash/fp';
import {IBrand} from "@/types/Brand.interface";

export default defineComponent({
  name: "BrandsPage",
  components: {
    // RadioGroup,
    // RadioGroupLabel,
    //// RadioGroupDescription,
    // RadioGroupOption,
  },
  setup(props, ctx) {

    const router = useRouter();
    const route = useRoute();

    const {stg} = useAppSettings()
    const brands = stg.brands;
    const initialBrandSelected = 'velosofi';
    const selected = ref(brands[initialBrandSelected])
    let stopWatchingSelected:WatchStopHandle;
    let stopWatchingRoute:WatchStopHandle;

    const handleSelectBrand = (event:Event, brand:IBrand) => {
      selected.value = brands[brand.id];

      return
    }

    onMounted(() => {
      stopWatchingSelected = watch(selected, (value, oldValue) => {
        //console.log('watch.selected: ', value.id);
        router.push(value.router_path);
      })
      stopWatchingRoute = watch(route, (value, oldValues) => {
        // console.log('stopMainWatch: ', newValues.path)
        processPath(value.path);
      })
      //
      processPath(route.path);
    })

    onUnmounted(() => {
      stopWatchingSelected();
      stopWatchingRoute();
    })

    const goToSelectedPath = () => {
      router.push(selected.value.router_path);
    }

    const processPath = (path:string) => {

      if (stg.nav.path_levels.level_1?.toLowerCase() !== 'brands') {
        return;
      }

      const brand_page_type = stg.nav.path_levels.level_2
      if (!brand_page_type) {
        selected.value = brands[initialBrandSelected];
        goToSelectedPath();
        return;
      }
      selected.value = brands[brand_page_type] || brands[initialBrandSelected];

      /*
      const parts = path.split('/').filter((s) => !!s)
      const n_parts = parts.length;
      let db_selected = '';
      console.log(path, ' - 0: ', nth(0, parts), ', 1: ', nth(1, parts), ', db_selected: ', db_selected);
      */
    }

    const ensureNavSelectedIsBrand = (path_part:string) => {
      if (path_part !== 'brands') {
        return
      }
      console.log('brands nav_id')
      stg.nav.selected_id = path_part;
    }

    return {
      brands,
      selected,
      handleSelectBrand
    }
  }
})
</script>

<style>

.brands-page {}

.brands-page .brand-page {}
.brands-page .brand-image {
  clip-path: circle(15px at center)
}
</style>