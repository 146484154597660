
export interface INavItem {
    id: string,
    name: string,
    href: string,
    current: boolean
}

export class NavItem implements INavItem {
    current: boolean;
    href: string;
    id: string;
    name: string;
    constructor(id: string, name: string, href: string, current: boolean) {
        this.id = id;
        this.name = name;
        this.href = href;
        this.current = current;
    }
}