

import {defineComponent} from "vue";
import {useAppSettings} from "@/main";
import {IPageSettingInfo, PageTypes} from "@/types/AppTypes";

export default defineComponent({
  name: "",
  setup() {

    const {stg} = useAppSettings()
    const page:IPageSettingInfo = stg.page[PageTypes.about];

    return {
      page
    }
  }
})
