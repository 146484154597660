<template>
  <div class="about">

    <div class="py-12 bg-white">


      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="lg:text-center">
          <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">{{page.name}}</h2>
          <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl" v-if="page.header">
            {{page.header}}
          </p>
          <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto" v-if="page.body">
            {{page.body}}
          </p>
        </div>
      </div>



      <div class="mt-10">

        <div v-for="(section, k) in page.sections" :key="k" >

          <div class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 m-2 ">

            <div v-for="(item, k) in section.items" :key="k"
                 :aria-current="`${item.name} brand page`" :alt="`${item.name} brand page`"
                 class="flex border border-md border-white brand-item hover:rounded-md hover:border text-gray-500 p-2 relative bg-white hover:ring-2 hover:ring-offset-2 hover:ring-offset-sky-300 hover:ring-white hover:ring-opacity-60 hover:bg-purple-300AAA hover:bg-opacity-75 hover:text-whiteAAA"
            >
              <div class=" w-full">
                <!--                <div class="">-->
                <!-- <component :is="brand.header.img" class="h-6 w-6" aria-hidden="true" />-->
                <!-- <img v-if="brand.header.img" :src="require(`../${brand.header.img}`)" class="h-6 w-6" aria-hidden="true" />-->
                <img v-if="item.img" :src="require(`../${item.img}`)"
                     class="h-10 w-10 left-n-30AAA absolute p-1 float-left ml-2 left-0 flex items-center justify-center h-16 w-16 rounded-full bg-indigo-500AAA bg-white border text-white"
                     aria-hidden="true"/>
                <!-- <img v-if="brand.header.img" src="../assets/img/brands/velosofi/velosofi-icon.png" class="h-6 w-6" aria-hidden="true" />-->
                <!--                </div>-->
                <!--                <p class="sm:ml-16AAA text-lg leading-6 font-medium text-gray-900 brand-item-name"></p>-->
                <p class="w-full ">
                  <span class="text-lg leading-6 font-medium text-gray-900 brand-item-nameAAA block">
                    {{ item.name }}
                  </span>
                  <span class="mt-2 mx-8AAA text-base text-blue-500 brand-item-infoAAA block mx-14">
                    {{ item.header }}
                  </span>
                  <span class="mt-2 mx-8AAA text-base text-gray-500AAA brand-item-infoAAA block mx-14" v-for="(body_entry, b_index) of item.body" :key="b_index">
                    {{ body_entry }}
                  </span>
                </p>
                <div class="flex flex-row items-center justify-center mt-2" >
                    <div class="" v-for="(url_entry, k2) of item.urls" :key="k2" >
                      <a :href="url_entry.url" target="_blank">
                        <img :src="require(`../${url_entry.img}`)" class="h-6 w-6 rounded-md" alt="">
                      </a>
                    </div>
                </div>
              </div>

            </div>

          </div>

        </div>




      </div>

    </div>

  </div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import {useAppSettings} from "@/main";
import {IPageSettingInfo, PageTypes} from "@/types/AppTypes";

export default defineComponent({
  name: "",
  setup() {

    const {stg} = useAppSettings()
    const page:IPageSettingInfo = stg.page[PageTypes.about];

    return {
      page
    }
  }
})
</script>

<style>

</style>
