<template>
  <div class="page-not-found">

    <h3 class="title text-red-400"> {{msg.title}} </h3>
    <h4 class="text-gray-700"> {{ msg.code }} {{msg.user_msg}} </h4>

    <p class="my-3">
      params: {{ $route.params }}, <br>
      query: {{ $route.query }}, <br>
    </p>

    <p class="my-2">
      {{ $route }}
    </p>

  </div>
</template>

<script lang="ts">

import {defineComponent, reactive} from "vue";
import { useRoute } from 'vue-router';

export default defineComponent({
  name: "NotFoundPage",
  setup() {

    const route = useRoute();
    // const router = useRouter();
    // const routeHash = useRouteHash()

    const msg = reactive({
      code: 404,
      title: 'Error',
      user_msg: 'Page not found'
    })
    return {
      msg,
      // route,
    }
  }
})
</script>

<style >

.page-not-found {}
.page-not-found .title {  }

</style>