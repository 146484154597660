<template>
  <div class="home">
<!--    <router-link to="/about" key="About">About</router-link>-->
    <div class="py-12 bg-white">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

        <div class="lg:text-center" v-for="(section, k) in page.sections" :key="k">
          <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">{{section.name}}</h2>
          <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {{section.header}}
          </p>
          <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto" v-for="(entry, e_index) of section.body" :key="e_index">
<!--            Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.-->
            {{entry}}
          </p>
        </div>

        <div class="mt-10">
          <div class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 m-2 ">

            <router-link :to="brand.router_path" :aria-current="`${brand.name} brand page`" :alt="`${brand.name} brand page`"
                         v-for="(brand, k) in brands" :key="k"
                         class="flex border border-md border-white brand-item hover:rounded-md hover:border text-gray-500 p-2 relative bg-white hover:ring-2 hover:ring-offset-2 hover:ring-offset-sky-300 hover:ring-white hover:ring-opacity-60 hover:bg-blue-900 hover:bg-opacity-75 hover:text-white"
            >
              <div class=" w-full">
<!--                <div class="">-->
                  <!-- <component :is="brand.header.img" class="h-6 w-6" aria-hidden="true" />-->
                  <!-- <img v-if="brand.header.img" :src="require(`../${brand.header.img}`)" class="h-6 w-6" aria-hidden="true" />-->
                  <img v-if="brand.header.img" :src="require(`../${brand.header.img}`)"
                       class="h-10 w-10 left-n-30AAA absolute p-1 float-left ml-2 left-0 flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500AAA bg-white border text-white"
                       aria-hidden="true"/>
                  <!-- <img v-if="brand.header.img" src="../assets/img/brands/velosofi/velosofi-icon.png" class="h-6 w-6" aria-hidden="true" />-->
<!--                </div>-->
<!--                <p class="sm:ml-16AAA text-lg leading-6 font-medium text-gray-900 brand-item-name"></p>-->
                <p class="w-full ">
                  <span class="text-lg leading-6 font-medium text-gray-900 brand-item-name block">
                    {{ brand.name }}
                  </span>
                  <span class="mt-2 mx-8AAA text-base text-gray-500AAA brand-item-info block mx-14">
                    {{ brand.sub_header?.text }}
                  </span>
                </p>
              </div>

            </router-link>
          </div>
        </div>



      </div>
    </div>



  </div>

<!--  <app-footer class="sticky bottom-0 text-small w-full" msg="Registered in England and Wales, No. 12663487"></app-footer>-->

<!--  <div class="w-16 h-16 bg-red">
    <img :src="require('@/assets/logo.png')" alt="">
&lt;!&ndash;    <img :src="require(brands[0].header.img)" alt="">&ndash;&gt;
&lt;!&ndash;    <img :src='require(`@/assets/img/brands/velosofi/velosofi-icon.png`)' alt="">&ndash;&gt;
    <img :src='require(`../${brands[0].header.img}`)' alt="">
&lt;!&ndash;    <img :src="require('../assets/logo.png')" alt="">&ndash;&gt;
  </div>-->
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import {useAppSettings} from "@/main";
import {IBrand} from "@/types/Brand.interface";
import AppFooter from "@/components/AppFooter.vue";
// import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'Home',
  components: {
    // HelloWorld,
    // AppFooter
  },
  setup(props, ctx) {

    const {stg} = useAppSettings()
    const brands = computed(() => stg.brands)
    const page = computed(() => stg.page.home)
    const toImagePath = (path?:string):string | undefined => {
      if (!path) { return undefined }
      console.log(path);
      return require(`${path}`)
    }
    return {
      page,
      brands,
      publicPath: process.env.BASE_URL,
      toImagePath
    }
  }
});
</script>


<style>
.left-n-20 {
  left: -20px !important;
}
.left-n-30 {
  left: -30px !important;
}
.brand-item {}
.brand-item:hover .brand-item-name , .brand-item:hover .brand-item-info{
  color: white;
}
.brand-item-name {
}
.brand-item-info {
}
</style>