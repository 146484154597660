

import {defineComponent, onMounted, onUnmounted, ref, watch} from "vue";
import {

  Switch,
  SwitchGroup,
  SwitchLabel,

  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue';

import {AtSymbolIcon, ArrowNarrowRightIcon, CursorClickIcon, XIcon} from '@heroicons/vue/outline'
import {WatchStopHandle} from "@vue/runtime-core";

export default defineComponent({
  name: "SayHi",
  components: {
    XIcon,
    CursorClickIcon,
    ArrowNarrowRightIcon,
    AtSymbolIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  setup() {

    const isOpen = ref(false);
    const enabled = ref(false);
    const makeAddress = ():string => {
      const p1 = 'Al';
      const p2 = 'Omneris'
      const p3 = '.com';
      const out = [p1, '@', p2, p3].join('');
      return out;
    }
    const closeModal = () => {
      isOpen.value = false
    }
    const openModal = () => {
      isOpen.value = true
    }

    let stopWatchingIsOpen:WatchStopHandle;
    onMounted(() => {
      stopWatchingIsOpen = watch(isOpen, (open, oldValue) => {
        if (!open) {
          setTimeout(() => {
            enabled.value = false;
          }, 200);
        }
      });
    })

    onUnmounted(() => {
      stopWatchingIsOpen();
    })

    return {
      enabled,
      isOpen,
      openModal,
      closeModal,
      makeAddress
    }
  }
})
