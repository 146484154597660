

import {defineComponent, ref, computed, reactive, watch, onMounted, onUnmounted} from 'vue'

import {useStore} from "vuex";

import {
  Dialog, DialogOverlay, DialogTitle,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'

/*
import {
  BellIcon, MenuIcon, XIcon, LockClosedIcon, LogoutIcon,
  XCircleIcon, AcademicCapIcon, AtSymbolIcon,
  ChipIcon
} from '@heroicons/vue/outline'
*/

import {MenuIcon, XIcon, AtSymbolIcon} from '@heroicons/vue/outline'

import {INavItem, NavItem} from "@/types/NavItem.interface";
// import {useAppSettings} from "@/main";
// import {AnyObject, SomeObject} from "@/types/AppTypes";
import {useRouter} from "vue-router";
import {useAppSettings} from "@/main";
import SayHi from "@/components/SayHi.vue";


const default_nav_item: INavItem = new NavItem('home', 'Home', '/', true);
const navigation:Record<string, INavItem> = {
  'home': new NavItem('home', 'Home', '/', true),
  'brands': new NavItem('brands', 'Brands', '/brands', false),
  'about': new NavItem('about', 'About', '/about', false),
  // new NavItem('sample', 'Sample', '/sample', false),
  // new NavItem('auth', 'Login', '/login', false),
  // new NavItem('auth_login', 'Login', '/user/login', false),
  // new NavItem('auth_signup', 'Sign Up', '/user/signup', false),
  // new NavItem('auth_forgot', 'Forgot', '/user/forgot', false),
}

function getCurrent(nav_items: Record<string, INavItem>): INavItem {
  for (const [key, value] of Object.entries(nav_items)) {
    if (value.current) {
      return value;
    }
  }
  return default_nav_item;
}

export default defineComponent({
  name: 'AppNavBar',
  components: {
    SayHi,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    // Menu,
    // MenuButton,
    // MenuItem,
    // MenuItems,
    // BellIcon,
    // AtSymbolIcon,
    MenuIcon,
    XIcon,
    // LogoutIcon,
    // ChipIcon,
    // Dialog,
    // DialogOverlay,
    // DialogTitle,
    // //DialogDescription,
    // TransitionRoot,
    // TransitionChild,
    // PasswordInfo,
    // XCircleIcon,
    // AcademicCapIcon,
    // AtSymbolIcon,
    // LockClosedIcon,
  },
  setup() {

    //const store = useStore();
    const {stg} = useAppSettings()
    const router = useRouter();
    // const open = ref(false)

    const isAuthenticated = computed(() => {
          return false
        }
    );
    const terms = reactive({
      conditions: () => {
        console.log('terms.conditions');
      },
      cookies: () => {
        console.log('terms.cookies');
      },
      privacy: () => {
        console.log('terms.privacy');
      },
    })

    //
    let item: INavItem = getCurrent(navigation);
    // const nav_id = ref(item.id);
    const nav_id = computed({
      get():string {
        const value = stg.nav.selected_id;
        console.log('GET.nav_id: ', value)
        return value
      },
      set(v:string) {
        stg.nav.selected_id = v
      }
    })

    const forceOpen = ref(false);
    const menuIsOpen = ref(false);
    //

    const setCurrentItem = (item: INavItem, open:boolean) => {
      //this.current_item = item;
      nav_id.value = item.id;
      // setCurrentBy(item, this.navigation)
      // console.log(nav_id.value);
      router.push(item.href);
      // open.value = false;
      // dc.open = false;
      menuIsOpen.value = false;
      // console.log(nav_id.value, ' - open:', open, ', menuIsOpen: ', menuIsOpen.value);
    }
    const keyPressToggleDisclosure = (event:KeyboardEvent, open:boolean) => {
      // console.log('key: ', event.code);
      toggleDisclosure(event, open);
    };
    const toggleDisclosure = (event:any, open:boolean) => {
      //console.log('BEFORE.toggleDisclosure - open:', open, ', menuIsOpen: ', menuIsOpen.value);
      const isOpening = !open;
      const isMenuOpening = !menuIsOpen.value;
      const same = isOpening === isMenuOpening;
      setTimeout(()=> {
        if (same) {
          menuIsOpen.value = isMenuOpening;
        }else{
          if (menuIsOpen.value === isOpening) {
            menuIsOpen.value = !isOpening;
          }else{
            menuIsOpen.value = isOpening;
          }
        }
        // console.log('AFTER.toggleDisclosure - open:', open, ', menuIsOpen: ', menuIsOpen.value);
      }, 10)
      //openMenu.value = false;
    }

    onMounted(() => {
      nav_id.value = item.id;
      stg.nav.selected_id = item.id;
    })

    onUnmounted(() => {
      return
    });

    return {
      navigation,
      setCurrentItem,
      toggleDisclosure,
      keyPressToggleDisclosure,
      forceOpen,
      menuIsOpen,
      terms,
      nav_id,
      isAuthenticated,
      nav: computed(() => stg.nav)
    }
  },
})
