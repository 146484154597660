

import {defineComponent} from "vue";
import {useAppSettings} from "@/main";

export default defineComponent({
  name: "BrandIseatuWorkPage",
  components: {

  },
  setup(props, ctx) {
    const {stg} = useAppSettings();
    const brand = stg.brands['iseatu-work']
    return {
      brand
    }
  }
})
